<template>
  <WidgetFrame @onResize="onResize">
    <template v-slot:title>
      {{ $t('permission.staff_skills') }}
    </template>
    <template v-slot:content>
      <template v-if="!canView('COMPANY', ['STAFF']) || !canView('SKILL', ['name']) || !canView('SKILL')">
        <div class="center-text">
          {{ $t('entity_selector.error.insufficient_permission_to_show_data') }}
        </div>
      </template>
      <template v-else-if="!loaded">
        <div class='status-message'>Loading...</div>
      </template>
      <template v-else-if="noSkills">
        <div class='status-message'>No skills</div>
      </template>
      <template v-else>
          <div class="input-group">
            <div class="input-group-prepend">
              <label class="input-group-text" for="dateEnd">
                {{$t('dashboard.widgets.date_range')}}
              </label>
            </div>
            <b-form-select id="dateEnd" 
              :data-vv-as="$t('dashboard.widgets.date_range')"
              data-vv-name="dashboard.widgets.date_range"
              data-vv-delay="500"
              :options="optionTypes"
              text-field="label"
              value-field="key"
              v-model="type"
              />
          </div>
          <div class='chart-holder'>
            <ag-charts-vue v-if="loaded" :options="options"></ag-charts-vue>
          </div>
          <Pager v-if="sourceData.length > pageSize"
            :sourceData="sourceData"
            :pageSize="pageSize"
            :small="width < 450"
            @paged="onPageChanged"
            />
      </template>
    </template>
  </WidgetFrame>
</template>

<script>
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import {AgChartsVue} from 'ag-charts-vue';
import { skillService } from '@/services';
import settings from "@/_dashboardSettings";
import cColors from "@/_chartColors";
import { EventBus } from '@/helpers';
import { tooltipRenderer } from '@/helpers/ag-chart-tooltip-renderer';

export default {
  name: 'HomeStaffSkillsWidget',
  components: {
    WidgetFrame: () => import('@/components/Dashboard/WidgetFrame'),
    Pager: () => import('@/components/Pager'),
    AgChartsVue,
  },
  props: {
    profile:    { type: Object, required: true },
  },
  watch: {
    type(newValue, oldValue) {
      if (oldValue != null) {
        this.saveSettings();
        this.buildData();
      }
    }
  },
  computed: {
    optionTypes() {
      return [
        {key: 'today', label: this.$t('date.today')},
        {key: 'plus_3m', label: this.$t('dashboard.plus_3_months')},
        {key: 'plus_6m', label: this.$t('dashboard.plus_6_months')},
        {key: 'plus_12m', label: this.$t('dashboard.plus_12_months')},
      ];
    },
    companyrule() {
      if (this.$store.state.company && this.$store.state.company.type !== 'Primary' &&
          this.$store.state.company.filterIds) {
        const companies = this.$store.state.company.filterIds;
        const companyrule = ['SKILL.STAFF.COMPANY.uuId', 'within', companies.join('|')];
        return companyrule
      }
      return null;
    }
  },
  data: function() {
    return {
      orientation: null,
      width: 0,
      height: 0,
      loaded: false,
      noSkills: false,
      options: null,
      sourceData: [],
      highest: 0,
      skills: null,
      type: null,
      targetDay: null,
      pageSize: 25,
      page: 0,
      skillLevelNames: []
    }
  },
  created() {
    EventBus.$on('theme-change', () => {
      if (this.loaded) {
        this.buildChart();
      }
    });

    // Use 'today' default type if profile doesn't have a valid key
    const pType = this.profile.optionType;
    this.type = !(this.optionTypes.map(t => t.key).includes(pType)) ? 'today' : pType;
    this.buildData();
  },
  beforeDestroy() {
    EventBus.$off('theme-change');
  },
  methods: {
    buildData() {
      this.sourceData = [];
      this.page = 0;

      // Build our comparison date
      this.targetDay = moment();
      if (this.type == 'plus_3m') {
        this.targetDay.add(3, 'M');
      } else if (this.type == 'plus_6m') {
        this.targetDay.add(6, 'M');
      } else if (this.type == 'plus_12m') {
        this.targetDay.add(12, 'M');
      }
      this.targetDay.startOf('day');
      // console.log("targetDay: " + this.targetDay.format())

      if (this.canView('SKILL')) {
        const requests = [
          skillService.listDashboard({companyrule: this.companyrule})
          , this.$store.dispatch('data/enumList')
        ]

        Promise.allSettled(requests).then(responses => {
          if (responses.find(i => i.status != 'fulfilled') == null) {
            const enumResp = responses[1].value
            if (enumResp != null) {
              if (enumResp.jobCase != null && enumResp[enumResp.jobCase] != null) {
                const propertyList = enumResp[enumResp.jobCase]
                const obj = propertyList.SkillLevelEnum
                const codes = Object.keys(obj)
                const list = []
                for (const c of codes) {
                  if (obj[c] < 0) {
                    continue
                  }
                  list.push({ value: c, text: c, num: obj[c] })
                }
                this.skillLevelNames.splice(0, this.skillLevelNames.length, ...list.map(i => i.value))
              }
            }

            const listDashboardResp = responses[0].value
            this.skills = listDashboardResp.data;
            if (this.skills.length == 0) {
              this.noSkills = true;
            } else {
              this.processSkills();
              this.buildChart();
            }
            this.loaded = true;
          }
        })
      }
      
    },
    buildChart() {
      this.sourceData.sort(function(a, b) {
        if (!a.name) {
          return 0;
        }
        return a.name.localeCompare(b.name);
      });
      this.options = settings.getBarChartOptions(this.width);
      // Only use the portion of the dataset we've paged into
      let start = this.page * this.pageSize;
      let end = Math.min(start + this.pageSize, this.sourceData.length);
      this.options.data = this.sourceData.slice(start, end);
      this.options.title.text = this.skills.length + ' Skills';
      
      this.options.series[0].xKey = 'name';
      this.options.series[0].yKeys = this.skillLevelNames;
      this.options.series[0].yNames = this.skillLevelNames;
      this.options.series[0].fills = [cColors.getThemeColor('bs-success'), cColors.getThemeColor('bs-info'), cColors.getThemeColor('bs-danger')];
      this.options.series[0].strokes = [cColors.getThemeColor('bs-success'), cColors.getThemeColor('bs-info'), cColors.getThemeColor('bs-danger')];
      this.options.axes[0].title = {
        enabled: true,
        text: this.$t('location.field.headCount')
      };
      this.options.axes[0].min = 0;
      this.options.axes[0].max = this.highest;
      
      this.options.series[0].tooltip = {
        enabled: true,
        renderer: tooltipRenderer
      }      
    },
    processSkills() {
      const self = this;
      this.skills.forEach(skill => {
        var staff = [];
        
        skill.staffList.forEach(s => {
          var giveStart = (!s.startDate || s.startDate == "1970-01-01");
          var giveEnd = (!s.endDate || s.endDate == "3000-01-01");
          var staffStart = moment(s.startDate).startOf('day');
          var staffEnd = moment(s.endDate).startOf('day');

          if ((giveStart || staffStart.isSameOrBefore(self.targetDay)) &&
              (giveEnd || staffEnd.isSameOrAfter(self.targetDay))) {
            staff.push(s);
          } else {
            // console.log("Not Adding: " + s.firstName + " " + s.lastName + " | " + s.startDate + " - " +s.endDate);
          }
        });

        const propData = {}
        for (const level of this.skillLevelNames) {
          propData[level] = staff.filter(s => s.skillLevel == level).length;
        }
        var total = staff.length;
        if (total > self.highest) {
          self.highest = total;
        }
        self.sourceData.push({'name': skill.name, 'total': total, ...propData});
      });
     
    },
    saveSettings() {
      this.profile.optionType = this.type;
      this.$emit('saveWidget', this.profile);
    },
    onResize({width, height}) {
      this.width = width;
      this.height = height;
      // 35 pixels per row, round up to even number
      this.pageSize = Math.floor(this.height / 35);
      if (this.loaded) {
        this.buildChart();
      }
    },
    tooltipNumberFormatter(num) {
      if (num == null || isNaN(num)) {
        return '0';
      }
      return parseFloat(num).toFixed(3).toString();
    },
    onPageChanged(page) {
      this.page = page;
      this.buildChart();
    }
  },
}
</script>

